import { ALL_APPS, QUALITY, YIELD, ENERGY, ASSETS, PLANNING } from "./config";
import homeIcon from "../Assets/icons/home.png";
import qualityIcon from "../Assets/icons/quality.png";
import yieldIcon from "../Assets/icons/yield.png";
import energyIcon from "../Assets/icons/energy.png";
import assetsIcon from "../Assets/icons/assets.png";
import planningIcon from "../Assets/icons/planning.png";

/*
Section for application filter icons. Add new elements to the array and it will
cater them automatically provided UNIQUE name and icon.

* @param {string} FilterTag UNIQUE
* @param {image} Image

{ name: ALL_APPS, icon: homeIcon }

*/

export const appFilterDetails = [
  { name: ALL_APPS, icon: homeIcon },
  { name: QUALITY, icon: qualityIcon },
  { name: YIELD, icon: yieldIcon },
  { name: ENERGY, icon: energyIcon },
  { name: ASSETS, icon: assetsIcon },
  { name: PLANNING, icon: planningIcon },
];
