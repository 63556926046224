import insightsImage from "../Assets/people.jpg";

/* 
Add new objects to this array and they will appear under Fonterra Insights section

* @param {string} John Smith
* @param {string} CEO
* @param {image} Image

{
    name: "John Smith",
    role: "CEO",
    image: insightsImage,
}

*/

export const ALL_PEOPLE = [
  {
    name: "Melody Taylor",
    role: "GM Insights & Intelligence",
    image: require("../Assets/people/melody.jpg"),
  },
  {
    name: "Oliver Van Woerden",
    role: "Strategic Integration and Portfolio Lead",
    image: require("../Assets/people/ollie.jpg"),
  },
  {
    name: "Oleg Barbin",
    role: "Principal Data Scientist",
    image: require("../Assets/people/oleg.jpg"),
  },
  {
    name: "Julian Wall",
    role: "IOC Business Partner Manager",
    image: require("../Assets/people/julian.jpg"),
  },
  {
    name: "Ryan Schoeltens",
    role: "IOC Lead Technologist",
    image: require("../Assets/people/ryan.jpg"),
  },
  {
    name: "Jason Wright",
    role: "IOC Lead Technologist",
    image: require("../Assets/people/jason.jpg"),
  },
  {
    name: "Bruce Zhang",
    role: "IOC Technologist",
    image: require("../Assets/people/bruce.jpg"),
  },
  {
    name: "Rubin Saini",
    role: "IOC Technologist",
    image: require("../Assets/people/rubin.jpg"),
  },
  {
    name: "Matthew Proctor",
    role: "Senior Data Scientist",
    image: require("../Assets/people/matt.jpg"),
  },
  {
    name: "Irina Boiarkina",
    role: "Senior Data Scientist",
    image: require("../Assets/people/irina.jpg"),
  },
  {
    name: "Kim Essenberg",
    role: "Data Scientist",
    image: require("../Assets/people/kim.jpg"),
  },
  {
    name: "Pascal Eun Sig Cheon",
    role: "Data Scientist",
    image: require("../Assets/people/pascal.jpg"),
  },
  {
    name: "Galina Pavlova",
    role: "Business Graduate",
    image: insightsImage,
  },
];
