import { useEffect, useContext } from "react";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import { AppRunTimeContext } from "../../Context/index";
import NavbarPC from "./NavbarPC";
import NavbarMob from "./NavbarMob";

import "./NavigationBar.css";

function NavigationBar() {
  const { isMobile } = useScreenDetector();
  const { g_searchQuery, g_setSearchQuery, g_username } =
    useContext(AppRunTimeContext);

  useEffect(() => {
    return () => {};
  }, [g_searchQuery]);

  if (isMobile)
    return (
      <NavbarMob
        searchText={g_searchQuery}
        setsearchText={g_setSearchQuery}
        g_username={g_username}
      />
    );

  return (
    <NavbarPC
      searchText={g_searchQuery}
      setsearchText={g_setSearchQuery}
      g_username={g_username}
    />
  );
}

export default NavigationBar;
