import { Row, Col, Image } from "react-bootstrap";

const NameTiles = ({ name, position, image }) => {
  return (
    <Col xs={6} sm={6} md={6} lg={4} xl={4}>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="fon-insights-tile"
      >
        <Row>
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            <Image
              src={image}
              alt={name}
              className="img-responsive fon-insights-app-image"
            />
          </Col>
          <Col
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            className="fon-insights-tile-text"
          >
            <p>
              <span className="fon-insights-tile-name inter-400">{name}</span>
            </p>
            <p>
              <span className="fon-insights-tile-position inter-300">
                {position}
              </span>
            </p>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default NameTiles;
