import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Newspaper } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { useScreenDetector } from "../../Hooks/useScreenDetector";

const Insight = ({ image, heading, description, link, date, showLink }) => {
  const { isMobile } = useScreenDetector();

  return (
    <Row className="insights-row">
      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <Image
          src={image}
          alt="insights"
          className="img-responsive insights-app-image"
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <h3>
          <Newspaper color="#89A5BE" size={16} />
          <span className="insights-title inter-400">INISIGHTS</span>
        </h3>
        <p className="insights-heading inter-400">{heading}</p>
        {!isMobile && <p className="insights-desc inter-400">{description}</p>}
        <p className="insights-date inter-400">{date}</p>
        {showLink && (
          <Link to="/insights">
            <Button
              className="insights-btn inter-500"
              variant="outline-primary"
            >
              View All
            </Button>
          </Link>
        )}
      </Col>
    </Row>
  );
};

export default Insight;
