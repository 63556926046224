import { useMsal, useAccount } from "@azure/msal-react";
import HeroSection from "./HeroSection";
import AppFilter from "./AppFilter";
import AppShowcase from "./AppShowcase";
import Insights from "../../Components/Insights/Insights";
import FonterraInsights from "./FonterraInsights";
import "./home.css";
import { useContext, useEffect } from "react";
import { AppRunTimeContext } from "../../Context";

const Home = () => {
  const { g_setUsername } = useContext(AppRunTimeContext);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account?.name) {
      const userInitials = account.name.split(" ");
      g_setUsername(userInitials[0][0] + userInitials[1][0]);
    }
    return () => {};
  }, [account, inProgress, instance]);

  return (
    <>
      <HeroSection />
      <AppFilter />
      <AppShowcase />
      <Insights items={1} showLink={true} />
      <FonterraInsights />
    </>
  );
};

export default Home;
