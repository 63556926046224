export const handleBookmark = (appId) => {
  let bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
  if (bookmarks.includes(appId))
    bookmarks = bookmarks.filter((id) => id !== appId);
  else bookmarks.push(appId);
  localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
};

export const loadBookmarks = () => {
  let bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
  return bookmarks;
};
