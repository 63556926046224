import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import NameTiles from "../../Components/NameTiles";
import { TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { ALL_PEOPLE } from "../../Config/people";
import { config } from "../../Config/config";

const FonterraInsights = () => {
  const { isMobile } = useScreenDetector();

  return (
    <Container fluid style={{ background: "#001a2d" }}>
      <Row className="fon-insights-main-row">
        <Col
          xs={12}
          sm={12}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
        >
          <h1 className="fon-insights-heading inter-500">
            <span>{config.fonterraInsights.title}</span>
          </h1>
          {!isMobile && <br />}
          {!isMobile && <br />}
          <Row>
            <Col xs={12} sm={12} md={9} lg={9} xl={9}>
              <p className="fon-insights-subheading inter-400">
                <span>{config.fonterraInsights.people.title}</span>
              </p>
              <Row>
                {ALL_PEOPLE.map((person) => (
                  <NameTiles
                    name={person.name}
                    position={person.role}
                    image={person.image}
                  />
                ))}
              </Row>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
              <p className="fon-insights-contact-us inter-400">
                <span>{config.fonterraInsights.contact.title}</span>
              </p>
              <p className="fon-insights-contact-email inter-400">
                <EnvelopeFill size={16} />
                <span style={{ marginLeft: "10px" }}>
                  {config.fonterraInsights.contact.email}
                </span>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FonterraInsights;
