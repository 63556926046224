// App Categories
export const ALL_APPS = "All Apps";
export const QUALITY = "Quality";
export const YIELD = "Yield";
export const ENERGY = "Energy";
export const ASSETS = "Assets";
export const PLANNING = "Planning";

//Change text values here for updating any static info on the app

export const config = {
  header: {
    title: "Insights & Intelligence",
    subheading:
      "To develop a core capability in COO where,through leveraging data and innovation, we can monitor,integrate, plan and optimise across the value chain.",
  },
  insights: {
    title: "Insights",
  },
  fonterraInsights: {
    title: "Fonterra Insights",
    people: {
      title: "Brought to you by",
    },
    contact: {
      title: "Contact Us",
      email: "IntegratedOperations@fonterra.com",
    },
  },
};
