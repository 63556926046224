import Button from "react-bootstrap/Button";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import { handleBookmark } from "../../helper";

const AppDetails = ({ app, _key, isProto, isMobile, setRefresh }) => {
  if (!((isProto && isProto === app.isProto) || (!isProto && !app.isProto)))
    return <></>;

  return (
    <Col key={_key} xs={6} sm={6} md={6} lg={3} xl={3}>
      <Col
        className={`showcase-app-col 
          ${isMobile && "showcase-app-col-mob"}`}
      >
        <Col
          xs={{ span: 2, offset: 9 }}
          sm={{ span: 2, offset: 9 }}
          md={{ span: 2, offset: 10 }}
          lg={{ span: 2, offset: 10 }}
          xl={{ span: 2, offset: 10 }}
          className={`bookmark-icon ${
            app.isBookmarked ? "bookmark-icon-fill" : ""
          }`}
          onClick={() => {
            handleBookmark(app.id);
            setRefresh((prev) => prev + 1);
          }}
        >
          {app.isBookmarked ? (
            <BookmarkFill size={20} />
          ) : (
            <Bookmark size={20} />
          )}
        </Col>

        <img
          src={app.icon}
          alt={app.name}
          className="img-responsive showcase-app-image"
        />

        <p className="showcase-app-name inter-500">{app.name}</p>
        {!isMobile && (
          <p className="showcase-app-desc inter-400">
            {app.desc.length >= 100
              ? app.desc.substr(app.desc, 95) + "..."
              : app.desc}
          </p>
        )}
        <Button
          className="showcase-app-btn d-flex align-items-center justify-content-center text-center inter-500"
          href={app.URL}
          target="_blank"
        >
          View
        </Button>
      </Col>
    </Col>
  );
};

export default AppDetails;
