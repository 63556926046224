import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import { config } from "../../Config/config";

const HeroSection = () => {
  const { isMobile } = useScreenDetector();

  return (
    <Container fluid className="hero-section">
      <Row>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
          style={{ marginTop: "120px" }}
        >
          <h1 className="hero-main inter-700">{config.header.title}</h1>
          {!isMobile && (
            <Col
              md={{ offset: 2, span: 8 }}
              lg={{ offset: 1, span: 10 }}
              xl={{ offset: 1, span: 10 }}
            >
              <h3 className="hero-text inter-400">
                {config.header.subheading}
              </h3>
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
