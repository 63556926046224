import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Pages/Home/index";
import Insights from "./Pages/Insights/index";
import ScrollToTop from "./Hooks/scrollToTop";

import NavigationBar from "./Components/Navbar";

const PageContent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/insights" element={<Insights />} />
    </Routes>
  );
};

function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router basename="/">
          <ScrollToTop />
          <NavigationBar />
          <PageContent />
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
