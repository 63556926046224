import { useState, createContext } from "react";
import { ALL_APPS } from "../Config/config";

//These are global variables
export const AppRunTimeContext = createContext({
  g_activeAppFilter: ALL_APPS,
  g_searchQuery: "",
  g_username: "#",
});

export const ContextProvider = (props) => {
  const [g_activeAppFilter, g_setActiveAppFilter] = useState(ALL_APPS); //Contains active app filter
  const [g_searchQuery, g_setSearchQuery] = useState(""); //Contains search query
  const [g_username, g_setUsername] = useState("#"); //Contains user initials

  return (
    <AppRunTimeContext.Provider
      value={{
        g_activeAppFilter: g_activeAppFilter,
        g_setActiveAppFilter: g_setActiveAppFilter,
        g_searchQuery: g_searchQuery,
        g_setSearchQuery: g_setSearchQuery,
        g_username: g_username,
        g_setUsername: g_setUsername,
      }}
    >
      {props.children}
    </AppRunTimeContext.Provider>
  );
};
