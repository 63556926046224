import { ALL_APPS, QUALITY, YIELD, ENERGY, ASSETS, PLANNING } from "./config";

//App Search Tags
const BOILER = "Boiler";
const CHEESE = "Cheese";
const POWDER = "Powder";
const DRYER = "Dryer";
const EVAPORATORS = "Evaporators";
const ENVIRONMENT = "Environment";
const TESTING = "Testing";
const CREAM_CHEESE = "Cream Cheese";
const MILK_VAT = "Milk Vat";
const RAW_MILK = "Raw Milk";
const CIP = "CIP";
const CREAM = "Cream";
const LACTOSE = "Lactose";
const PERMEATE = "Permeate";
const SUPPLY_CHAIN = "Supply Chain";
const PRODUCT_MOVEMENT = "Product Movement";
const FREIGHT = "Freight";
const MICRO = "Micro";
const STEAM = "Steam";
const GAS = "Gas";
const LOSSES = "Losses";
const PEAK = "Peak";
const CASEIN = "Casein";
const UHT = "UHT";

/*
Add new elements to the array and it will appear in the app section
Please Note - App ID should be unique as bookmarking logic relies on it.
              isBookmarked should always be set to FALSE as bookmarking logic relies on it.
              categories array should always have ALL_APPS otherwise it will not appear.

cater them automatically provided UNIQUE name and icon.

* @param {string} id UNIQUE
* @param {string} App Name
* @param {Image} App Screenshot
* @param {string} App Description
* @param {boolean} Is app a prototype?
* @param {boolean} Is app bookmarked? ALWAYS FALSE
* @param {string} URL to app
* @param {Array} All the categories the app belong too. DEFAULT - ALL_APPS
* @param {Array} All the relevant tags for the app.

{
    id: "1 Boiler Chemistry",
    name: "Boiler Chemistry",
    icon: require("../Assets/apps/boiler_chemistry.png"),
    desc: "Improve the chemistry of water in boilers (and other boiler operation monitoring and optimisation) across NZM by using process data to visualise the parameters of dosing and blowdown event key inputs and tracking the outputs such as water pH. This is currently live for Clandeboye Boilers",
    isProto: true,
    isBookmarked: false,
    URL: "https://fonterra.seeq.site/view/0EEE7CD9-B6A2-6200-89A3-2BC4E18884DF",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [BOILER],
}
*/

export const ALL_APP_DETAILS = [
  {
    id: "1 Boiler Chemistry",
    name: "Boiler Chemistry",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EY8SLYfqe4NMjqT1x-DjHSsBJh5Ohrom7jvDE0fPTCqjQg?e=4D7NDb",
    icon: require("../Assets/apps/boiler_chemistry.png"),
    desc: "Improve the chemistry of water in boilers (and other boiler operation monitoring and optimisation) across NZM by using process data to visualise the parameters of dosing and blowdown event key inputs and tracking the outputs such as water pH. This is currently live for Clandeboye Boilers",
    isProto: true,
    isBookmarked: false,
    URL: "https://fonterra.seeq.site/view/0EEE7CD9-B6A2-6200-89A3-2BC4E18884DF",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [BOILER],
  },
  {
    id: "2 Boiler Efficiency",
    name: "Boiler Efficiency",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EdA3BJwVTVhKsXUafMs4us4Bl3Ye_PebwIj1W3kcmZR86A?e=rDJgmY",
    icon: require("../Assets/apps/boiler_efficiency.png"),
    desc: "",
    isProto: false,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/c81fb865-f0ad-454b-91fb-68d609c7aebb/reports/301e60a4-c5a5-4ca1-a649-8d352750b988/ReportSection?experience=power-bi",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [BOILER],
  },
  {
    id: "3 Cheese Composition Dashboard",
    name: "Cheese Composition Dashboard",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETMXkq8JdmBKkpUzVOWae40BUrjVNuviZZx6DVOgr-EhzQ?e=wloReq",
    icon: require("../Assets/apps/cheese_composition.png"),
    desc: "Shows the performance across key composition metrics by cheese plant. Ability to gain insights from the trends across all composition parameters.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/CompDashCheese.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CHEESE],
  },
  {
    id: "4 Digital Valves",
    name: "Digital Valves",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETS_I6frFw1Nk2ELUk2FcyEB4EwNM-_OcZCj5jZJ7xvlgg?e=ka6td2",
    icon: require("../Assets/apps/digital_valves.png"),
    desc: "",
    isProto: true,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/me/apps/9248ea95-fbf5-4142-b61a-4cd7d7a4964d/reports/4e6686bd-7075-42cf-ae93-5a0368ea10ca/ReportSection314e39d701976b895679?ctid=c5d7dc78-3f45-4492-8053-9441aee4531b&language=en-US&experience=power-bi",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [],
  },
  {
    id: "5 Dryer Dashboard STD",
    name: "Dryer Dashboard STD",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/Edx0DL6_7YxCuFZpgr6Tab0BRSmPrg3a6Nr6L2KDNuhfjg?e=CG1dWG",
    icon: require("../Assets/apps/dryer_dashboard_std.png"),
    desc: "Dryer Dashboard displaying key process parameters and outcomes. Available for all Powder Dryers and displays season to date information",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/DryerDashboard_STD_DD1.html",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [POWDER, DRYER],
  },
  {
    id: "6 Dryer Dashboard Almost Live",
    name: "Dryer Dashboard Almost Live",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EUYUeuKvg2JAmR6viDjIeLABGxTu17IdmW8MkDtqk4DtqQ?e=J7xqEM",
    icon: require("../Assets/apps/dryer_dashboard_almost_live.png"),
    desc: "This report shows key process parameters and performance of Dryers in the last hour. The time is displayed at the top of the page as to when it was last updated.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/DryerOverViewDashboard.html",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [POWDER, DRYER],
  },
  {
    id: "7 DryerScoreCard",
    name: "DryerScoreCard",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EREAus_cWelOi2ock7HUk48BoJu_-auKxC_QD73bpGA51g?e=HfV16P",
    icon: require("../Assets/apps/dryer_scorecard.png"),
    desc: "Outputs of all Dryers including PWF, Rate and Steam. This can be displayed by factory, material or OEM",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/DryerScoreCard.html",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [POWDER, DRYER],
  },
  {
    id: "8 EASY",
    name: "EASY",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EVMrBdd6BSdAgJVozMXRf5cBj7-82udw4hh5t21ZJqz3Pg?e=PWOVQS",
    icon: require("../Assets/apps/easy.png"),
    desc: "",
    isProto: true,
    isBookmarked: false,
    URL: "https://fon-aw-prd-lab-app-lin-ds-easy-orchestration.fon-aw-prd-hub-ase-lin-001.appserviceenvironment.net/schedule",
    categories: [ALL_APPS, ENERGY, PLANNING],
    searchDescTags: [],
  },
  {
    id: "9 EnviromentalExceptionAlert",
    name: "EnviromentalExceptionAlert",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETsa9auvMRdKqjiNEfgskegBsMESeMy3R8l7F15EGWOoTw?e=yUNAMx",
    icon: require("../Assets/apps/environmental_exceptions.png"),
    desc: "Environmental exception report in last 21 days with drill down ability to individual sites. This covers Yeast, Moulds, Coliforms, EB, Crono, L & S charted for each site. For risk and exception avoidance for all other sample points, use MIAS in the first instance.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/EnviromentalExceptionAlert.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [ENVIRONMENT, TESTING],
  },
  {
    id: "10 Evaporator Dashboard",
    name: "Evaporator Dashboard",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETxgXd6xy9VGl6-iAdE-n6oByd1fW_QhguNXAeMFfXtzqg?e=NGwcMW",
    icon: require("../Assets/apps/evaporator_dashboard.png"),
    desc: "This report shows key process parameters and performance of Evaporators across the season.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/EvapDashboard_BTWR.html",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [EVAPORATORS],
  },
  {
    id: "11 Drain Loss Anomaly Detection",
    name: "Drain Loss Anomaly Detection",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EVvegLf1OdVMhX2AdztFO6sBuBQjGolqHZPYQqYpenwvNQ?e=pa2SOM",
    icon: require("../Assets/apps/drain_loss_anomaly_detection.png"),
    desc: "Provides a daily summary to decide if losses down the flumes at the site are higher than normal and should be investigated. The model uses turbidity, flow and the production plan to estimate whether losses are high, normal, low or suspicious The aim is pick up excess losses in a timely manner.",
    isProto: true,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/me/apps/9248ea95-fbf5-4142-b61a-4cd7d7a4964d/reports/29bfe592-8bb6-4fd3-a13c-d02641274dc0/ReportSection9aa1be390d7e59d3825c?ctid=c5d7dc78-3f45-4492-8053-9441aee4531b&language=en-US&experience=power-bi",
    categories: [ALL_APPS, YIELD],
    searchDescTags: [],
  },
  {
    id: "12 Gas Nominations (2 week steam forecast)",
    name: "Gas Nominations (2 week steam forecast)",
    // icon:"",
    icon: require("../Assets/apps/gas_nominations.png"),
    desc: "",
    isProto: false,
    isBookmarked: false,
    URL: "#",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [],
  },
  {
    id: "13 Powders Grading Correlation",
    name: "Powders Grading Correlation",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EUlhOkd9KGFFkfwJFwFpvrMBeTwNTQI3L9azVV2EgEF3TQ?e=KcQks3",
    icon: require("../Assets/apps/powders_grading_correlation.png"),
    desc: "Correlates grading results to process parameters to support problem solving. Available for Insolubility Index, Heat Stability, Coffee Sediment, Bulk Density, WPNI, Powder Aggregation, Wettability, Image Analysis solubility",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/GradingCorrelationTable.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [POWDER, TESTING],
  },
  {
    id: "14 Insight Reporting",
    name: "Insight Reporting",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EbN9Fi-rIFRHv6AYInCnD44BpdpF4N_xYapJCIUkKkvUvQ?e=JaElf7",
    icon: require("../Assets/apps/insights_reporting.png"),
    desc: "Summarises risks with upcoming production plan and provides insights as to why connecting to Technical Knowledge Base and IOC quality Insights charts",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/InsightReporting.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [],
  },
  {
    id: "15 Gas Usage",
    name: "Gas Usage",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EWLGgNP8JMZNrGINL7agYQsB8QoC24Bl1xWB_lnUxmZn1A?e=5wF3k1",
    icon: require("../Assets/apps/gas_usage.png"),
    desc: "This report has been rapidly put together to provide a high-level visualization of Gas Usage across the North Island sites. The data combines the FY23 BCP Energy Usage and FY23 Packed Tonnage, and should be considered an order of magnitude estimate rather than precise usage data. We are progressing work towards providing more accurate and detailed information as the next level of deeper reporting",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/IOC_GasUsage.html",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [GAS],
  },
  {
    id: "16 Cheese Drought",
    name: "Cheese Drought",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EV0iJcikMDFFn-wBiqbt4iIBt4eje7dC1LqLtQfIzBnaog?e=0rM4tj",
    icon: require("../Assets/apps/cheese_drought.png"),
    desc: "Tracking of drought indicator parameters to allow conversation around if an area is experiencing drought and the severity.  Used on the drought calls.",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_CheeseDrought/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CHEESE],
  },
  {
    id: "17 CIP Kitchens",
    name: "CIP Kitchens",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EWVu9jp7mYZAg2pfOoFM-eABEw9F8u9bKdH0PZ2bXOcWxQ?e=Wtfq2b",
    icon: require("../Assets/apps/cip_kitchens.png"),
    desc: "Monitoring of CIP kitchen performance to identify opportunities for improvement.  Used on the CIP DIG.",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_CIP_Kitchens/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CIP],
  },
  {
    id: "18 Composition Dashboard",
    name: "Composition Dashboard",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETq2k_93GcpMtCk2FoSUNwkB-4yOiGFmtRxLZ5bxSp2MIA?e=di96nQ",
    icon: require("../Assets/apps/composition_dashboard.png"),
    desc: "A tool for plants to improve their composition performance.  Checks the current Olga targets vs the recommended targets, as well as performance of the plant and performance of the controllers.     Composition control insight for YIELD DIG Check if targets are correct When NZM use it's for pre-campaign checks and WMS",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Composition_Dashboard ",
    categories: [ALL_APPS, YIELD],
    searchDescTags: [],
  },
  {
    id: "19 Cream Cheese Monitor",
    name: "Cream Cheese Monitor",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EfPyjINi9yZJkdYdhPE0LbwBhOFC91fA7T1rY346UHAGIg?e=wIKHhI",
    icon: require("../Assets/apps/cream_cheese_monitor.png"),
    desc: "Monitoring of cream cheese plant performance to identify areas of improvement and optimisation",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_CreamCheeseMonitor/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CREAM_CHEESE],
  },
  {
    id: "20 Cream Products Monitor",
    name: "Cream Products Monitor",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/Ef_wqOHi8NZCtvVkUgZxkYABuVza0PBGawnGYxGHo7JIjQ?e=3ENUyW",
    icon: require("../Assets/apps/cream_products_monitor.png"),
    desc: "A tool to monitor key cream parameters",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_CreamProductsMonitor ",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CREAM],
  },
  {
    id: "21 Evaporator Health Metrics",
    name: "Evaporator Health Metrics",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/Efb_v9O_Su9FvbjW_kLSelMBTJ19dcQ1yETNJsdDRucYtg?e=YMak6c",
    icon: require("../Assets/apps/evap_health.png"),
    desc: "Tracking of identified evaporator metrics which are critical to running an evaporator in a stable way.  Helps to indicate areas of improvement for evaporators across the country.",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Evap_Health_Metrics ",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [EVAPORATORS],
  },
  {
    id: "22 Lactose Dashboard",
    name: "Lactose Dashboard",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EQ9FGxuzVg5Ehf5nDk10g6oBhR0lixpO6kV0GyYamVm8IA?e=Ai9zuy",
    icon: require("../Assets/apps/lactose_dashboard.png"),
    desc: "Monitoring of lactose and permeate use nationally",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Lactose_Dashboard ",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [LACTOSE],
  },
  {
    id: "23 Lactose Performance Metrics",
    name: "Lactose Performance Metrics",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EYo10J31keZLp07CNEwPrq8Bvor9A3EWvF6QC5hr0M3t3w?e=eFJfIo",
    icon: require("../Assets/apps/lactose_performance_metrics.png"),
    desc: "Performance monitoring for lactose plants to identify opportunities for improvement and optimisation",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_LactosePerformanceMetrics/",
    categories: [ALL_APPS, QUALITY, YIELD],
    searchDescTags: [LACTOSE],
  },
  {
    id: "24 MIAS",
    name: "MIAS",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EWdo0Ios4_xEjQlR45dLQH0B2TWL0tJsoPkRUyOrTyPYwg?e=hIqHsb",
    icon: require("../Assets/apps/mias.png"),
    desc: "An app to identify quality risk in the business.  Has a large user group and forms the basis for the quality DIG.         Uses the results from the last 90 days plus in-process testing results to determine risk",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_MIAS/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [MICRO],
  },
  {
    id: "25 Microgens",
    name: "Microgens",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EY6zFLy4JfFEg2ARC-TrmKsBnxSAUKhSoivI8WXPC-WTaw?e=momRMX",
    icon: require("../Assets/apps/microgens.png"),
    desc: "Tracking of silo contact time and microgens to identify improper management of silos.  Used on the micro DIG.",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Micro/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [MICRO],
  },
  {
    id: "26 MVT Monitor",
    name: "MVT Monitor",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EYoOnmUlffFOmDBoRD9wCUwBMJp5NPXZH4J6YS08uFlTwQ?e=OZBecA",
    icon: require("../Assets/apps/mvt_monitor.png"),
    desc: "Monitoring of the accuracy of the milk vat telemetry system - shows when a tanker is mismatched from vats or gone out of calibration for temperature. Tracking data accuracy of MVT vendors, individual farms, and milk tankers.",
    isProto: false,
    isBookmarked: false,
    URL: "https://mvt.ioc.fonterra.com/mvtmonitor/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [MILK_VAT],
  },
  {
    id: "27 IOC_PeakMilk",
    name: "IOC_PeakMilk",
    // icon:"",
    icon: require("../Assets/apps/ioc_peakmilk.png"),
    desc: "An app to view carryover volumes across the country.  Used in the efficiency DIG and peak milk calls",
    isProto: false,
    isBookmarked: false,
    URL: "https://peakmilk.ioc.fonterra.com/ioc_peakmilk/",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [PEAK],
  },
  {
    id: "28 Production Plan At Risk",
    name: "Production Plan At Risk",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EemzFexDwxNKq0QgFEqa6nMBF4Scm6Y-eV4D71edvSg5-w?e=G7xx37",
    icon: require("../Assets/apps/PPAR.png"),
    desc: "An app to identify risk in the upcoming production plan.  Looks at the materials coming up in the plan for plants as well as what those materials have previously been downgraded for to give an indication of risk to those plants              -               Risk of product being out of spec based on last time plant made it - uses historical results of when the plant last made the product",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_ProductionPlanAtRisk ",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [],
  },
  {
    id: "29 IOC_Rennet_Casein_Moisture",
    name: "IOC_Rennet_Casein_Moisture",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETSCiYd2skJNgTRlhe1Hh-4BskDsjwUySkHd7fOrCtQRNg?e=G5w0nz",
    icon: require("../Assets/apps/rennet_casein.png"),
    desc: "Uses ash and moisture content to inform the plant of where to run the plant to keep ash in spec and optimise moisture     - to manage moistures when ash is low which is Dec-March and July-Aug",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Rennet_Casein_Moisture/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [CASEIN],
  },
  {
    id: "30 IOC_Sensory_Explorer",
    name: "IOC_Sensory_Explorer",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ETBy3ChJdF5HttY3r5sT244BYiNMhW3nI1L7AKkUskzhjA?e=Qnes8v",
    icon: require("../Assets/apps/sensory_explorer.png"),
    desc: "Tracking of sensory performance on a long term basis to show long term sensory trend, as well as emerging trends and a comparison to previous performance.  Used by the sensory programme aimed at improving sensory performance for powders.            Risks and trends regarding powder sensory ",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_Sensory_Explorer ",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [POWDER],
  },
  {
    id: "31 Stability",
    name: "Stability",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ERo4e5VnWf1Grbrz9OrPBmkB1t6bUMG7R3pCF7cEi0shLQ?e=xtk3KM",
    icon: require("../Assets/apps/stability.png"),
    desc: "Highlights risk on APA, CIP, Exceptions, Planned & Unplanned downtime, run length to provide insights on stability risk",
    isProto: false,
    isBookmarked: false,
    URL: "https://stability.ioc.fonterra.com/ioc_stability/",
    categories: [ALL_APPS, ASSETS],
    searchDescTags: [],
  },
  {
    id: "32 The Last Drop",
    name: "The Last Drop",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EbZhxqjW45BCis8cFBOwv58B9Zo57QPqWcu-bwTaDRS5cA?e=YwkmWk",
    icon: require("../Assets/apps/last_drop.png"),
    desc: "Monitoring of silo losses prior to CIP and identification of the size of the loss opportunity if silo volume prior to CIP is addressed.              Estimates product left in silo at point of CIP become engaged (product that will therefore end up in the drain)",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_TheLastDrop/",
    categories: [ALL_APPS, YIELD],
    searchDescTags: [],
  },
  {
    id: "33 UHT",
    name: "UHT",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EU7-jLR9zx9Ni85tSD6YCUMBJUz3N33ftFzMvAr98cmRFQ?e=84wrVm",
    icon: require("../Assets/apps/uht.png"),
    desc: "A UHT specific app to allow monitoring of steriliser performance.  This is used by the technical teams to understand when to change the run length (at certain times of the season the run length needs to be cut back to maintain quality performance).  Also tracks composition and permeate addition metrics for use in the daily DMS.",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/IOC_UHT ",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [UHT],
  },
  {
    id: "34 KapuniLactose",
    name: "KapuniLactose",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EWnqMrZ77vVBgyvq6txM7toBHbEYjhGFNvJnYgj5qAjzKg?e=XHuDhD",
    icon: require("../Assets/apps/kapuni_lactose.png"),
    desc: "Supports Kapuni with understanding key process parameters and outcomes to nitric environmental loading. ",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/KapuniLactose.html",
    categories: [ALL_APPS, QUALITY, PLANNING, YIELD, ASSETS],
    searchDescTags: [LACTOSE],
  },
  {
    id: "35 Losses Explorer",
    name: "Losses Explorer",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ERRFvXHhf5xOjO4et7kvn8UBbj0iJotPqrKi60MK_nzsNg?e=5OeLc0",
    icon: require("../Assets/apps/losses_explorer.png"),
    desc: "A tool to provide tracking of drain losses for sites as well as performance comparison to the previous season",
    isProto: false,
    isBookmarked: false,
    URL: "https://lossesexplorer.ioc.fonterra.com/losses_explorer/",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [],
  },
  {
    id: "36 Raw Milk at 7am",
    name: "Raw Milk at 7am",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EepAMrKB43VChz2FT66Mvm4BhWX0mFy1ZygJsO-wA5SEQg?e=FkjeCO",
    icon: require("../Assets/apps/7am.png"),
    desc: "Visualises the volume of raw milk onsite at 7am",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/Milk7am.html",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [],
  },
  {
    id: "37 MOJO",
    name: "MOJO",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EaYmRTa1ze9Bq-6W4hm7W8kB2Yra9m9X-ih63LYuye4qdg?e=PctRWd",
    icon: require("../Assets/apps/mojo.png"),
    desc: "",
    isProto: false,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/c81fb865-f0ad-454b-91fb-68d609c7aebb/reports/07cd273d-1a65-46cd-9e34-8f8a60356a63?experience=power-bi",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [],
  },
  {
    id: "38 Raw Milk Seasonality Attributes",
    name: "Raw Milk Seasonality Attributes",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EcyS98LSmaNPgib5JDGcH4UBTjoEYzdjVC5Sh1qpNhQNGA?e=TdYfz7",
    icon: require("../Assets/apps/rm_seasonality.png"),
    desc: "Aspire raw milk Composition attributes, showing seasonality trends back to FY05",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/National_Milk_Attributes_IOC.html",
    categories: [ALL_APPS, QUALITY, PLANNING],
    searchDescTags: [RAW_MILK],
  },
  {
    id: "39 Product Capability Tool",
    name: "Product Capability Tool",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ESjlnowQuURLuKW1BPCXIxkBWgSMN3yz9W3xLOHxaR7tOA?e=Uhghuq",
    icon: require("../Assets/apps/product_capability_tool.png"),
    desc: "This is a prototype is an evolution to combine all the IOC quality applications. It supports long term trending and benchmarking for all testing parameters, including IPT, FPT product survellience and LPT",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/ProductCapability_ProductPreGrade.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [],
  },
  {
    id: "40 Milk Impact & Processing Efficiency",
    name: "Milk Impact & Processing Efficiency",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EYpMoEx0R6xLlNe2mODeU9wBH4ZlP2NobxZf65qySaa5OA?e=lfhqLx",
    icon: require("../Assets/apps/impact.png"),
    desc: "Displays milk impact across regions and sites along with rate and MCT. Intended for peak milk processing period",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/Peak_Milk_Efficiency_InDevelopment.html",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [],
  },
  {
    id: "41 PPA Steam Forecast",
    name: "PPA Steam Forecast",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/ES2Lsq0V4WhGqxz7IQFuNrIB-gB6_kCwTcgUOmzFChx7Rw?e=oKgNA4",
    icon: require("../Assets/apps/steam_ppa.png"),
    desc: "",
    isProto: false,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/c81fb865-f0ad-454b-91fb-68d609c7aebb/reports/b4fb19f6-e35c-41db-afc4-9cb178960d2e/ReportSection9125740013060cd71b3c?experience=power-bi",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [STEAM],
  },
  {
    id: "42 Protein to Water Factor to Total Solids Calculator",
    name: "Protein to Water Factor to Total Solids Calculator",
    // icon:"https://fonterra.sharepoint.com/:i:/r/sites/IntegratedOperations2.0/Shared%20Documents/General/IOC%20Catalogue/Landing%20Page%20Screenshots/PWF%20Calculator.PNG?csf=1&web=1&e=4TztgW",
    icon: require("../Assets/apps/pwf.png"),
    desc: "Calculates total solids from PWF and visa versa. Applicable for Powders products and requires powder composition",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/PWF_TS_Calculator.html",
    categories: [ALL_APPS, QUALITY, YIELD, ENERGY],
    searchDescTags: [POWDER],
  },
  {
    id: "43 PWF Benchmarking",
    name: "PWF Benchmarking",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/Ef8Xuna5cW1BiAXk_yhQtRcB4kEXD065R1ItEmo13j_N3A?e=ta08IY",
    icon: require("../Assets/apps/pwf_benchmarking.png"),
    desc: "Benchmarks Protein to Water Factor across powder plants by product category. Protein to Water Factor (PWF) is a concentrate variable calculated from both the concentration and composition.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/PWFBenchmarking.html",
    categories: [ALL_APPS, QUALITY, YIELD, ENERGY],
    searchDescTags: [POWDER],
  },
  {
    id: "44 Pahiatua Milk Consumption by Equipment",
    name: "Pahiatua Milk Consumption by Equipment",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EbswuHuRddFHruEsg3tPUYIBX4Zx7rpp4PswEUi8VGkQqg?e=23EbLG",
    icon: require("../Assets/apps/pahiatua_consumption.png"),
    desc: "Shows milk consumption rates across different equipment items to support PLANNING and scheduling at Pahiatua",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/RossM.html",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [],
  },
  {
    id: "45 Silo Spinner",
    name: "Silo Spinner",
    // icon:"",
    icon: require("../Assets/apps/silo_spinner.jpg"),
    desc: "",
    isProto: true,
    isBookmarked: false,
    URL: "#",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [],
  },
  {
    id: "46 Spill Sheets",
    name: "Spill Sheets",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EQYR6jHc0H9Kg3rTy27kHPoBSNWPKzqTRPOXDeeqI0AdsA?e=X8P6dh",
    icon: require("../Assets/apps/spill_sheets.png"),
    desc: "Visualises all spill sheets' loss volume and reason category with the ability to filter by plant and product. It contains a summary and information from the last 15 days.",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/SpillSheets.html",
    categories: [ALL_APPS, YIELD],
    searchDescTags: [LOSSES],
  },
  {
    id: "47 Te Rapa Site Water Use Trending",
    name: "Te Rapa Site Water Use Trending",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EaHS4N9T0c9LkqCiYSfmeggBBXnKyDnIFsn2gODc9CSiZw?e=4tGhYe",
    icon: require("../Assets/apps/terapa_water.png"),
    desc: "Te Rapa site water use trending back to FY19",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/TeRapaH2ORPT.html",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [],
  },
  {
    id: "48 Thermophiles",
    name: "Thermophiles",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EUscXZPItodApCsspz_hHMgBPygnDWJ6VsBLEL82_BDHaA?e=OriwIj",
    icon: require("../Assets/apps/thermophiles.png"),
    desc: "A tracking tool for thermophile results and other related results (e.g. run length, nitrates, apc's).  Allows for benchmarking against other plants and identification of risk areas / thermophile indicators",
    isProto: false,
    isBookmarked: false,
    URL: "https://rshiny.mes.fonterra.com/Thermophiles ",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [MICRO],
  },
  {
    id: "49 Total Solids Monitoring",
    name: "Total Solids Monitoring",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EfJvDJnitN1MhLptY9M2EEQBKrKo6V9hsZytwSEE82507g?e=npGUqi",
    icon: require("../Assets/apps/ts_monitoring.png"),
    desc: "Total solids trending for all Powder Dryers including: WMP Weighted Averages, IWMP Weighted Averages, Benchmarking and Unweighted Valuation",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/TotalSolids.html",
    categories: [ALL_APPS, QUALITY, YIELD],
    searchDescTags: [POWDER],
  },
  {
    id: "50 Trace Back Monitoring",
    name: "Trace Back Monitoring",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EUBhOlW6fntKpeI6Bm0IXqYB003e3fKUDvosJYuu62_cww?e=Jmu4vN",
    icon: require("../Assets/apps/tracebacks.png"),
    desc: "Trace Backs (IPT) per site for Salmonella, Listeria, Cronobacter. It shows the factories on traceback and those who have had positive test results",
    isProto: true,
    isBookmarked: false,
    URL: "file://zeus/data/_Shared_NZ/_Manufacturing/IOC/Developing%20Prototypes/TraceBacks.html",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [],
  },
  {
    id: "51 Whareroa P3 energy Reporting",
    name: "Whareroa P3 energy Reporting",
    // icon:"",
    icon: require("../Assets/apps/peakmilk.png"),
    desc: "",
    isProto: true,
    isBookmarked: false,
    URL: "#",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [],
  },
  {
    id: "52 Whey Minerals",
    name: "Whey Minerals",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EVIzHIPde7ZGuLS_vDwg5coBOyfwL8U8Jt_DOn1X_mkK4A?e=NCZANu",
    icon: require("../Assets/apps/whey_minerals.png"),
    desc: "Monitoring levels of phosphorus and calcium at Whareroa and Kapuni",
    isProto: false,
    isBookmarked: false,
    URL: "https://wheyminerals.ioc.fonterra.com/ioc_whey_minerals/",
    categories: [ALL_APPS, QUALITY],
    searchDescTags: [LACTOSE, PERMEATE],
  },
  {
    id: "53 Product Movements",
    name: "Product Movements",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EcbUnwH3iz5Pif3hUovkcG4BpClI5UjQHc2x23d-LjI9qg?e=e1QHuy",
    icon: require("../Assets/apps/product_movements.png"),
    desc: "Insights to explore the ratio of internal product movements to exported product movements to identify insights on efficiency within the supply chain network.",
    isProto: true,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/me/reports/60df47a3-cf01-4f25-a369-6c8b28a63085/5a2c9d9ea6cb050b823e?ctid=c5d7dc78-3f45-4492-8053-9441aee4531b&experience=power-bi&bookmarkGuid=17904a6f-ba94-4692-b18d-1ba7b24e54ce",
    categories: [ALL_APPS, PLANNING],
    searchDescTags: [SUPPLY_CHAIN, PRODUCT_MOVEMENT, FREIGHT],
  },
  {
    id: "54 No Meter No Matter",
    name: "No Meter No Matter",
    // icon:"https://fonterra.sharepoint.com/:i:/r/sites/IntegratedOperations2.0/Shared%20Documents/General/IOC%20Catalogue/Landing%20Page%20Screenshots/No%20Meter%20No%20Matter.png?csf=1&web=1&e=P6oLaT",
    icon: require("../Assets/apps/no_meter_no_matter.png"),
    desc: "This tool uses sensors and APA states to calculate resource usage and costs. This aims to be the reference for an efficiently operated plant, to drive behaviour changes in real time.",
    isProto: true,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/c81fb865-f0ad-454b-91fb-68d609c7aebb/reports/5dd9eacf-16b6-43cc-a00f-fcd84e24eb29/22be7554717d9c24d335?ctid=c5d7dc78-3f45-4492-8053-9441aee4531b&experience=power-bi",
    categories: [ALL_APPS, ENERGY],
    searchDescTags: [STEAM],
  },
  {
    id: "55 CIP Turnaround",
    name: "CIP Turnaround",
    // icon:"https://fonterra.sharepoint.com/:i:/s/IntegratedOperations2.0/EVIzHIPde7ZGuLS_vDwg5coBOyfwL8U8Jt_DOn1X_mkK4A?e=NCZANu",
    icon: require("../Assets/apps/cip_turnaround.png"),
    desc: "Designed for tracking South Island Drier changeover times and CIPs. One of the workstreams under the South Island Capacity Programme is to enable quicker turnaround times on driers to ensure we can process peak milk volumes.",
    isProto: true,
    isBookmarked: false,
    URL: "https://app.powerbi.com/groups/c81fb865-f0ad-454b-91fb-68d609c7aebb/reports/09076962-3ea1-4630-b82a-67d40816373f/d010f753427824cc81f5?ctid=c5d7dc78-3f45-4492-8053-9441aee4531b&experience=power-bi&clientSideAuth=0",
    categories: [ALL_APPS, PLANNING, ASSETS],
    searchDescTags: [CIP],
  },
];
