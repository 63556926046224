export const msalConfig = {
  auth: {
    clientId: "4ec6bb99-74b8-4312-95d8-6d9c9d5e52b2",
    authority: "https://login.microsoftonline.com/fonterra.onmicrosoft.com",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
