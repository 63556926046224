import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../Assets/logo.svg";
import { Search } from "react-bootstrap-icons";

const NavbarMob = ({ searchText, setsearchText, g_username }) => {
  return (
    <Navbar expand="lg" variant="dark" className="sticky-top">
      <Container fluid>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
        <Navbar.Brand href="#home" className="m-auto">
          <img
            src={Logo}
            width="113"
            height="40"
            className="img-responsive"
            alt="IOC logo"
          />
        </Navbar.Brand>
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-md"
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="start"
        >
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <div className="d-flex nav-search-bar-mob">
          <Form.Control
            type="search"
            className="me-2 nav-search"
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
          />
        </div>
        <Search
          className={
            "search-icon search-icon-mob" + (searchText ? " n-visibility" : "")
          }
        />
        <div className="d-flex nav-search-name">
          <div className="circle inter-400">{g_username}</div>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarMob;
