import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import Insight from "./Insight";

import { ALL_INSIGHTS } from "../../Config/insights";

const Insights = ({ items, showLink }) => {
  const { isMobile } = useScreenDetector();

  return (
    <Container fluid>
      <Row style={{ margin: "50px 0px" }}>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
        >
          <h1 className="insights-main-heading inter-500">
            <span>Insights</span>
          </h1>
          {!isMobile && <br />}
          {!isMobile && <br />}
          <br />
          {ALL_INSIGHTS.slice(0, items ? items : ALL_INSIGHTS.length).map(
            (insight, index) => (
              <Insight
                key={index}
                image={insight.image}
                heading={insight.heading}
                description={insight.description}
                link={insight.link}
                date={insight.date}
                showLink={showLink}
              />
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Insights;
