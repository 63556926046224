import insightsImage from "../Assets/insights/placeholder.jpg";

/* 
Add new objects to this array and they will appear under Insights section
Please Note - Ordering is important here. 
              First element in the array will always appear on the home page. 
              Insight page will render and iterate the array in forward direction. 

* @param {image} Image
* @param {string} Main Heading
* @param {string} Some Description
* @param {URL} Some Link 
* @param {string} Date but string 

{
    image: insightsImage,
    heading: "Enter a relevant announcement title here 1",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
}

*/

export const ALL_INSIGHTS = [
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 3",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 4",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 5",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
  {
    image: insightsImage,
    heading: "Enter a relevant announcement title here 6",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "#",
    date: "23 Sept 2024",
  },
];
