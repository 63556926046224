import { useEffect, useContext, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { AppRunTimeContext } from "../../Context/index";
import { ALL_APP_DETAILS } from "../../Config/appShowcase";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import { loadBookmarks } from "../../helper";
import AppDetails from "../../Components/AppDetails/AppDetails";

const AppShowcase = () => {
  const { g_activeAppFilter, g_searchQuery } = useContext(AppRunTimeContext);
  const bookmarks = loadBookmarks();
  const { isMobile } = useScreenDetector();
  const [refresh, setRefresh] = useState(1);

  const sortedApps = useMemo(() => {
    const all_apps = [...ALL_APP_DETAILS].filter((app) => {
      const tagsToString = app.searchDescTags.join("").toLowerCase();
      app.isBookmarked = bookmarks.includes(app.id) ? true : false;
      return tagsToString.includes(g_searchQuery.toLowerCase());
    });

    return all_apps.sort((a, b) => {
      if (a.isBookmarked === b.isBookmarked) return 0;
      return a.isBookmarked ? -1 : 1;
    });
  }, [refresh, bookmarks, g_searchQuery]);

  useEffect(() => {
    return () => {};
  }, [g_activeAppFilter]);

  return (
    <Container fluid>
      <Row style={{ background: "#F3F5F8" }}>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
        >
          {/* NON PROTOTYPES */}
          <Row>
            {sortedApps.map((app, key) => {
              if (app.categories.includes(g_activeAppFilter))
                return (
                  <AppDetails
                    app={app}
                    _key={key}
                    isProto={false}
                    isMobile={isMobile}
                    setRefresh={setRefresh}
                  />
                );
              return "";
            })}
          </Row>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
        >
          {/* PROTOTYPES */}
          <h2 style={{ fontSize: "28px", marginTop: "60px" }}>Prototypes</h2>
          <hr />
          <Row>
            {sortedApps.map((app, key) => {
              return (
                <AppDetails
                  app={app}
                  _key={key}
                  isProto={true}
                  isMobile={isMobile}
                  setRefresh={setRefresh}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AppShowcase;
