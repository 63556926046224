import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Logo from "../../Assets/logo.svg";
import { Search } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const NavbarPC = ({ searchText, setsearchText, g_username }) => {
  return (
    <Navbar expand="lg" className="navbar-pc sticky-top">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            src={Logo}
            width="113"
            height="40"
            className="img-responsive"
            alt="IOC logo"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/">
              Portfolio
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className="d-flex nav-search-bar-pc">
          <Form.Control
            type="search"
            placeholder="Search..."
            className="me-2 nav-search"
            aria-label="Search"
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
          />
        </div>
        <Search
          className={"search-icon" + (searchText ? " n-visibility" : "")}
        />
        <div className="d-flex nav-search-name">
          <div className="circle">{g_username}</div>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarPC;
