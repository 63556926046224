import { useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { appFilterDetails } from "../../Config/filters";
import { AppRunTimeContext } from "../../Context/index";
import { useScreenDetector } from "../../Hooks/useScreenDetector";
import Form from "react-bootstrap/Form";

const _appFilter = (g_activeAppFilter, g_setActiveAppFilter) => {
  return (
    <Container fluid>
      <Row style={{ background: "#F3F5F8" }}>
        <Col
          xs={0}
          sm={0}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 1, span: 10 }}
          xl={{ offset: 1, span: 10 }}
          style={{ marginTop: "-50px" }}
        >
          <Row className="app-filters">
            {appFilterDetails.map((text, key) => {
              return (
                <Col
                  key={key}
                  className={
                    "app-icons inter-400" +
                    (g_activeAppFilter === text.name
                      ? " app-icons-highlight"
                      : "")
                  }
                  onClick={(e) => g_setActiveAppFilter(text.name)}
                >
                  <div
                    style={{
                      background:
                        g_activeAppFilter === text.name ? "#F3F5F8" : "",
                      padding: "0em 1em",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={text.icon}
                      alt={text.name}
                      className="img-responsive app-icon"
                    />
                    <span
                      className="icon-text"
                      style={{
                        fontWeight:
                          g_activeAppFilter === text.name ? "bold" : "",
                      }}
                    >
                      {text.name}
                    </span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const _MobAppFilter = (g_activeAppFilter, g_setActiveAppFilter) => {
  return (
    <Container fluid>
      <Row style={{ background: "#F3F5F8" }}>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 1, span: 10 }}
          md={0}
          lg={0}
          xl={0}
          style={{ marginTop: "-50px" }}
          className="app-filters-mob"
        >
          <Col xs={12}>
            <h1 className="app-filters-mob-title">Find App</h1>
            <Form.Select
              value={g_activeAppFilter}
              onChange={(e) => g_setActiveAppFilter(e.target.value)}
              className="mb-3"
              style={{
                color: "#134b7e",
                border: "1px solid #89A5BE",
                borderRadius: "5px",
              }}
            >
              {appFilterDetails.map((app, index) => (
                <option key={index} value={app.name}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {app.name}
                  </div>
                </option>
              ))}
            </Form.Select>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

const AppFilter = () => {
  const { isMobile } = useScreenDetector();

  const { g_activeAppFilter, g_setActiveAppFilter } =
    useContext(AppRunTimeContext);

  useEffect(() => {
    return () => {};
  }, [g_activeAppFilter]);

  return isMobile
    ? _MobAppFilter(g_activeAppFilter, g_setActiveAppFilter)
    : _appFilter(g_activeAppFilter, g_setActiveAppFilter);
};

export default AppFilter;
